/** @jsx jsx */
import { jsx, useThemeUI } from "theme-ui";
import { Fragment, useEffect } from "react";
import { Box, Flex } from "rebass";
import Hero from "../../components/Hero";
import ProjectFadeInTitle from "../../components/ProjectFadeInTitle";
import ProjectTitleListing from "../../components/ProjectTitleListing";
import BackToTop from "../../components/BackToTop";
import IntroImg from "../../img/ds/ds-cover.jpg";
import BookScene from "../../components/BookScene";
import Layout from "../../layouts/";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import ThemeColorSetter from "../../components/ThemeColorSetter";

const ProjectTest: React.FC = () => {
  const context = useThemeUI();
  const { theme } = context;
  const projectBgColor = theme?.colors?.oberonProjectBgEasybroker as string;
  const projectTextColor = theme?.colors?.oberonProjectTextEasybroker as string;
  const themeColor = theme?.colors?.boseProjectBgImmersive as string;
  const themeColorBg = theme?.colors?.background as string;

  useEffect(() => {
    const handleScroll = () => {
      const heroSection = document.getElementById("hero-section");
      if (heroSection) {
        const rect = heroSection.getBoundingClientRect();
        if (rect.bottom > 0) {
          document.body.classList.add("hero-visible");
          document.body.classList.remove("hero-hidden");
        } else {
          document.body.classList.add("hero-hidden");
          document.body.classList.remove("hero-visible");
        }
      } else {
        document.body.classList.add("hero-hidden");
        document.body.classList.remove("hero-visible");
      }
    };

    const heroSection = document.getElementById("hero-section");
    if (heroSection) {
      window.addEventListener("scroll", handleScroll);
      handleScroll(); // Initial check
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    } else {
      document.body.classList.add("hero-hidden");
      document.body.classList.remove("hero-visible");
    }
  }, []);

  return (
    <Layout>
      <ThemeColorSetter color={themeColor} colorBg={themeColorBg} />
      <main>
        <Header bgColor={projectBgColor} textColor={projectTextColor} />
        <Hero
          title={"Test"}
          titleRow={"APP"}
          titleClassName={"uppercase"}
          subtitle={"test copy"}
          shortDesc={<Fragment>Short desc</Fragment>}
          year={"2021"}
          role={<Fragment>Product Design</Fragment>}
          roleDesc={<Fragment>I did ... </Fragment>}
          disciplines={
            <Fragment>
              Branding &mdash; Content &mdash; Design &mdash; Development
            </Fragment>
          }
          challenge={<Fragment>Desc</Fragment>}
          img={IntroImg}
          projectBg={projectBgColor}
          projectText={projectTextColor}
          component={
            <BookScene
              coverColor={"#f0c844"}
              textColor={"silver"}
              urlTexture={"/models/textures/ds-book-cover.jpg"}
            />
          }
        />
        <Flex
          flexWrap="wrap"
          justifyContent="center"
          variant="container"
          id="project-content"
        >
          <Box width={[1 / 1, 7 / 12]} variant="containerBigTitle">
            <ProjectFadeInTitle
              word1={"Design"}
              word1ClassName={"outline"}
              word3={"for"}
              className={"uppercase"}
              word4={"Teams"}
              word4ClassName={"uppercase"}
            />
          </Box>
          <Box width={[1 / 1, 5 / 12]} />
        </Flex>
        <ProjectTitleListing />
        <BackToTop />
        <Footer />
      </main>
    </Layout>
  );
};

export default ProjectTest;
